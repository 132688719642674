import { googleAnalytics } from "@/lib/gtag"
import { useManagementErrorsStore } from "@/stores"
import IconButton from "../IconButton"
import Modal from "../Modal"
import { useUserWorkspacesInfiniteQuery } from "@/queries"
import { useAuth } from "@/providers/authContext"
import useCustomRouter from "@/hooks/useCustomRouter"
import { useCallback, useMemo } from "react"
import PlansUI from "../Setting/Plans"
import classNames from "classnames"
import { SettingProvider } from "../Setting/SettingProvider"

const PermissionGuestModal = () => {
  const { errorState, setErrorState } = useManagementErrorsStore()
  const { userInfoQuery } = useAuth()

  const isEnable = useMemo(() => {
    return {
      enabled1: errorState?.message.startsWith("Guest user does not have permission") ?? false,
      enabled2: errorState?.message.startsWith("Your subscription has ended") ?? false,
    }
  }, [errorState?.message])

  const { flattenData } = useUserWorkspacesInfiniteQuery({
    enabled: isEnable.enabled1,
  })

  const router = useCustomRouter()

  const handleClose = useCallback(() => {
    if (router.pathname.includes("/workspace/macros/")) {
      router.push(`/workspace/macros`)
    }

    setErrorState(null)
    googleAnalytics.event({
      action: "click",
      category: "modal",
      label: "close_permission_guest_modal",
      value: 1,
    })
  }, [setErrorState, router])

  const renderBody = useCallback(() => {
    if (isEnable.enabled1) {
      return (
        <>
          <h2 className="text-xl font-semibold text-center mb-4">Permission Denied</h2>
          <p className="text-sm mb-2 text-atherGray-300">With the Guest role, you cannot generate images</p>
          <p className="text-sm mb-2 text-atherGray-300">
            Please contact the{" "}
            <span className="font-semibold text-atherGray-0">
              {flattenData?.find(workspace => workspace.id === userInfoQuery?.data?.currentWorkspace?.id)?.name ?? ""}
            </span>{" "}
            Workspace owner to upgrade you to Member
          </p>
          <div className="flex items-center justify-end mt-6 space-x-2">
            <IconButton onClick={handleClose} className="px-4 font-semibold">
              Close
            </IconButton>
          </div>
        </>
      )
    }

    if (isEnable.enabled2) {
      return (
        <SettingProvider>
          <PlansUI isModal />
        </SettingProvider>
      )
    }

    return null
  }, [flattenData, handleClose, isEnable.enabled1, isEnable.enabled2, userInfoQuery?.data?.currentWorkspace?.id])

  return (
    <Modal
      bodyClassName="p-0"
      closeButtonClassName={classNames({
        "absolute top-2 right-2": isEnable.enabled2,
      })}
      className={classNames("overflow-visible max-w-md modal-content-child", {
        "max-w-[58rem] bg-black border border-atherGray-800 min-h-[52.5rem] pt-0 px-2": isEnable.enabled2,
      })}
      isOpen={!!errorState?.isOpen}
      onClose={handleClose}
    >
      {renderBody()}
    </Modal>
  )
}

export default PermissionGuestModal
